.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-\[1424px\] {
  top: 1424px;
}

.top-\[3384px\] {
  top: 3384px;
}

.\!ml-\[-10\.00px\] {
  margin-left: -10px !important;
}

.\!ml-\[-6\.00px\] {
  margin-left: -6px !important;
}

.\!mr-\[-10\.00px\] {
  margin-right: -10px !important;
}

.\!mr-\[-6\.00px\] {
  margin-right: -6px !important;
}

.mb-60 {
  margin-bottom: 15rem;
}

.mr-\[-1\.00px\] {
  margin-right: -1px;
}

.mr-\[-3\.00px\] {
  margin-right: -3px;
}

.mt-\[-0\.50px\] {
  margin-top: -.5px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-2\.00px\] {
  margin-top: -2px;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.\!h-\[20px\] {
  height: 20px !important;
}

.\!h-\[24px\] {
  height: 24px !important;
}

.\!h-\[32px\] {
  height: 32px !important;
}

.\!h-\[36\.18px\] {
  height: 36.18px !important;
}

.\!h-\[73px\] {
  height: 73px !important;
}

.\!h-\[79px\] {
  height: 79px !important;
}

.h-\[115\.53px\] {
  height: 115.53px;
}

.h-\[1429px\] {
  height: 1429px;
}

.h-\[153\.4px\] {
  height: 153.4px;
}

.h-\[167\.27px\] {
  height: 167.27px;
}

.h-\[214px\] {
  height: 214px;
}

.h-\[2312px\] {
  height: 2312px;
}

.h-\[2727px\] {
  height: 2727px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[370px\] {
  height: 370px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[430\.98px\] {
  height: 430.98px;
}

.h-\[451\.34px\] {
  height: 451.34px;
}

.h-\[46px\] {
  height: 46px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[572px\] {
  height: 572px;
}

.h-\[6412px\] {
  height: 6412px;
}

.h-\[668px\] {
  height: 668px;
}

.h-\[780\.87px\] {
  height: 780.87px;
}

.h-\[97\.89px\] {
  height: 97.89px;
}

.\!w-\[108\.55px\] {
  width: 108.55px !important;
}

.\!w-\[20px\] {
  width: 20px !important;
}

.\!w-\[213px\] {
  width: 213px !important;
}

.\!w-\[237px\] {
  width: 237px !important;
}

.\!w-\[24px\] {
  width: 24px !important;
}

.\!w-\[32px\] {
  width: 32px !important;
}

.\!w-\[unset\] {
  width: unset !important;
}

.\!w-full {
  width: 100% !important;
}

.w-\[1127px\] {
  width: 1127px;
}

.w-\[1129px\] {
  width: 1129px;
}

.w-\[1130px\] {
  width: 1130px;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[144px\] {
  width: 144px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[213px\] {
  width: 213px;
}

.w-\[214px\] {
  width: 214px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[328px\] {
  width: 328px;
}

.w-\[442px\] {
  width: 442px;
}

.w-\[443px\] {
  width: 443px;
}

.w-\[444px\] {
  width: 444px;
}

.w-\[556px\] {
  width: 556px;
}

.w-\[557px\] {
  width: 557px;
}

.w-\[559px\] {
  width: 559px;
}

.w-\[621\.28px\] {
  width: 621.28px;
}

.w-\[629\.36px\] {
  width: 629.36px;
}

.w-\[711px\] {
  width: 711px;
}

.w-\[99px\] {
  width: 99px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.\!flex-1 {
  flex: 1 !important;
}

.\!flex-\[0_0_auto\] {
  flex: none !important;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-\[104px\] {
  gap: 104px;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[120px\] {
  gap: 120px;
}

.gap-\[12px\] {
  gap: 12px;
}

.gap-\[130px\] {
  gap: 130px;
}

.gap-\[131px\] {
  gap: 131px;
}

.gap-\[16px\] {
  gap: 16px;
}

.gap-\[245px\] {
  gap: 245px;
}

.gap-\[24px\] {
  gap: 24px;
}

.gap-\[32px\] {
  gap: 32px;
}

.gap-\[371px\] {
  gap: 371px;
}

.gap-\[40px\] {
  gap: 40px;
}

.gap-\[48px\] {
  gap: 48px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[52px\] {
  gap: 52px;
}

.gap-\[64px\] {
  gap: 64px;
}

.gap-\[65px\] {
  gap: 65px;
}

.gap-\[8px\] {
  gap: 8px;
}

.gap-\[91px\] {
  gap: 91px;
}

.\!self-stretch {
  align-self: stretch !important;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-\[0px_0px_12px_12px\] {
  border-radius: 0 0 12px 12px;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[12px_12px_0px_0px\] {
  border-radius: 12px 12px 0 0;
}

.rounded-\[32px\] {
  border-radius: 32px;
}

.rounded-\[7px\] {
  border-radius: 7px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-\[0\.5px\] {
  border-bottom-width: .5px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#000000\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-\[\#0e0e0e\] {
  --tw-border-opacity: 1;
  border-color: rgb(14 14 14 / var(--tw-border-opacity));
}

.border-\[\#3949dd\] {
  --tw-border-opacity: 1;
  border-color: rgb(57 73 221 / var(--tw-border-opacity));
}

.border-blue {
  border-color: var(--blue);
}

.border-dark-gray {
  border-color: var(--dark-gray);
}

.border-red {
  border-color: var(--red);
}

.\!bg-white {
  background-color: var(--white) !important;
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-blue {
  background-color: var(--blue);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-white {
  background-color: var(--white);
}

.object-cover {
  object-fit: cover;
}

.p-\[16px\] {
  padding: 16px;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[40px\] {
  padding: 40px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.px-\[32px\] {
  padding-left: 32px;
  padding-right: 32px;
}

.px-\[48px\] {
  padding-left: 48px;
  padding-right: 48px;
}

.py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-\[4px\] {
  padding-bottom: 4px;
}

.pt-0 {
  padding-top: 0;
}

.text-center {
  text-align: center;
}

.font-dekstop-body-regular-16 {
  font-family: var(--dekstop-body-regular-16-font-family);
}

.font-dekstop-body-regular-20 {
  font-family: var(--dekstop-body-regular-20-font-family);
}

.font-dekstop-body2-regular-20 {
  font-family: var(--dekstop-body2-regular-20-font-family);
}

.font-dekstop-heading-semi-bold-32 {
  font-family: var(--dekstop-heading-semi-bold-32-font-family);
}

.font-dekstop-heading-semi-bold-40 {
  font-family: var(--dekstop-heading-semi-bold-40-font-family);
}

.font-dekstop-heading-semi-bold-64 {
  font-family: var(--dekstop-heading-semi-bold-64-font-family);
}

.font-dekstop-link-regular-24 {
  font-family: var(--dekstop-link-regular-24-font-family);
}

.text-\[length\:var\(--dekstop-body-regular-16-font-size\)\] {
  font-size: var(--dekstop-body-regular-16-font-size);
}

.text-\[length\:var\(--dekstop-body-regular-20-font-size\)\] {
  font-size: var(--dekstop-body-regular-20-font-size);
}

.text-\[length\:var\(--dekstop-body2-regular-20-font-size\)\] {
  font-size: var(--dekstop-body2-regular-20-font-size);
}

.text-\[length\:var\(--dekstop-heading-semi-bold-32-font-size\)\] {
  font-size: var(--dekstop-heading-semi-bold-32-font-size);
}

.text-\[length\:var\(--dekstop-heading-semi-bold-40-font-size\)\] {
  font-size: var(--dekstop-heading-semi-bold-40-font-size);
}

.text-\[length\:var\(--dekstop-heading-semi-bold-64-font-size\)\] {
  font-size: var(--dekstop-heading-semi-bold-64-font-size);
}

.text-\[length\:var\(--dekstop-link-regular-24-font-size\)\] {
  font-size: var(--dekstop-link-regular-24-font-size);
}

.font-\[number\:var\(--dekstop-body-regular-16-font-weight\)\] {
  font-weight: var(--dekstop-body-regular-16-font-weight);
}

.font-\[number\:var\(--dekstop-body-regular-20-font-weight\)\] {
  font-weight: var(--dekstop-body-regular-20-font-weight);
}

.font-\[number\:var\(--dekstop-body2-regular-20-font-weight\)\] {
  font-weight: var(--dekstop-body2-regular-20-font-weight);
}

.font-\[number\:var\(--dekstop-heading-semi-bold-32-font-weight\)\] {
  font-weight: var(--dekstop-heading-semi-bold-32-font-weight);
}

.font-\[number\:var\(--dekstop-heading-semi-bold-40-font-weight\)\] {
  font-weight: var(--dekstop-heading-semi-bold-40-font-weight);
}

.font-\[number\:var\(--dekstop-heading-semi-bold-64-font-weight\)\] {
  font-weight: var(--dekstop-heading-semi-bold-64-font-weight);
}

.font-\[number\:var\(--dekstop-link-regular-24-font-weight\)\] {
  font-weight: var(--dekstop-link-regular-24-font-weight);
}

.leading-\[var\(--dekstop-body-regular-16-line-height\)\] {
  line-height: var(--dekstop-body-regular-16-line-height);
}

.leading-\[var\(--dekstop-body-regular-20-line-height\)\] {
  line-height: var(--dekstop-body-regular-20-line-height);
}

.leading-\[var\(--dekstop-body2-regular-20-line-height\)\] {
  line-height: var(--dekstop-body2-regular-20-line-height);
}

.leading-\[var\(--dekstop-heading-semi-bold-32-line-height\)\] {
  line-height: var(--dekstop-heading-semi-bold-32-line-height);
}

.leading-\[var\(--dekstop-heading-semi-bold-40-line-height\)\] {
  line-height: var(--dekstop-heading-semi-bold-40-line-height);
}

.leading-\[var\(--dekstop-heading-semi-bold-64-line-height\)\] {
  line-height: var(--dekstop-heading-semi-bold-64-line-height);
}

.leading-\[var\(--dekstop-link-regular-24-line-height\)\] {
  line-height: var(--dekstop-link-regular-24-line-height);
}

.tracking-\[var\(--dekstop-body-regular-16-letter-spacing\)\] {
  letter-spacing: var(--dekstop-body-regular-16-letter-spacing);
}

.tracking-\[var\(--dekstop-body-regular-20-letter-spacing\)\] {
  letter-spacing: var(--dekstop-body-regular-20-letter-spacing);
}

.tracking-\[var\(--dekstop-body2-regular-20-letter-spacing\)\] {
  letter-spacing: var(--dekstop-body2-regular-20-letter-spacing);
}

.tracking-\[var\(--dekstop-heading-semi-bold-32-letter-spacing\)\] {
  letter-spacing: var(--dekstop-heading-semi-bold-32-letter-spacing);
}

.tracking-\[var\(--dekstop-heading-semi-bold-40-letter-spacing\)\] {
  letter-spacing: var(--dekstop-heading-semi-bold-40-letter-spacing);
}

.tracking-\[var\(--dekstop-heading-semi-bold-64-letter-spacing\)\] {
  letter-spacing: var(--dekstop-heading-semi-bold-64-letter-spacing);
}

.tracking-\[var\(--dekstop-link-regular-24-letter-spacing\)\] {
  letter-spacing: var(--dekstop-link-regular-24-letter-spacing);
}

.\!text-white {
  color: var(--white) !important;
}

.text-\[\#0e0e0e\] {
  --tw-text-opacity: 1;
  color: rgb(14 14 14 / var(--tw-text-opacity));
}

.text-\[\#3949dd\] {
  --tw-text-opacity: 1;
  color: rgb(57 73 221 / var(--tw-text-opacity));
}

.text-black {
  color: var(--black);
}

.text-blue {
  color: var(--blue);
}

.text-dark-blue {
  color: var(--dark-blue);
}

.text-dark-gray {
  color: var(--dark-gray);
}

.text-red {
  color: var(--red);
}

.text-transparent {
  color: #0000;
}

.text-white {
  color: var(--white);
}

.\[border-bottom-style\:solid\] {
  border-bottom-style: solid;
}

.\[font-style\:var\(--dekstop-body-regular-16-font-style\)\] {
  font-style: var(--dekstop-body-regular-16-font-style);
}

.\[font-style\:var\(--dekstop-body-regular-20-font-style\)\] {
  font-style: var(--dekstop-body-regular-20-font-style);
}

.\[font-style\:var\(--dekstop-body2-regular-20-font-style\)\] {
  font-style: var(--dekstop-body2-regular-20-font-style);
}

.\[font-style\:var\(--dekstop-heading-semi-bold-32-font-style\)\] {
  font-style: var(--dekstop-heading-semi-bold-32-font-style);
}

.\[font-style\:var\(--dekstop-heading-semi-bold-40-font-style\)\] {
  font-style: var(--dekstop-heading-semi-bold-40-font-style);
}

.\[font-style\:var\(--dekstop-heading-semi-bold-64-font-style\)\] {
  font-style: var(--dekstop-heading-semi-bold-64-font-style);
}

.\[font-style\:var\(--dekstop-link-regular-24-font-style\)\] {
  font-style: var(--dekstop-link-regular-24-font-style);
}

.\!\[justify-content\:unset\] {
  justify-content: unset !important;
}

.\!\[text-align\:unset\] {
  text-align: unset !important;
}

.\!\[white-space\:unset\] {
  white-space: unset !important;
}

:root {
  --black: #0e0e0e;
  --blue: #3949de;
  --dark-blue: #2d3fe1;
  --dark-gray: #999;
  --dekstop-body-regular-16-font-family: "Inter", Helvetica;
  --dekstop-body-regular-16-font-size: 16px;
  --dekstop-body-regular-16-font-style: normal;
  --dekstop-body-regular-16-font-weight: 400;
  --dekstop-body-regular-16-letter-spacing: 0px;
  --dekstop-body-regular-16-line-height: 24px;
  --dekstop-body-regular-20-font-family: "Inter", Helvetica;
  --dekstop-body-regular-20-font-size: 20px;
  --dekstop-body-regular-20-font-style: normal;
  --dekstop-body-regular-20-font-weight: 400;
  --dekstop-body-regular-20-letter-spacing: 0px;
  --dekstop-body-regular-20-line-height: 28px;
  --dekstop-body2-regular-20-font-family: "Inter", Helvetica;
  --dekstop-body2-regular-20-font-size: 20px;
  --dekstop-body2-regular-20-font-style: normal;
  --dekstop-body2-regular-20-font-weight: 400;
  --dekstop-body2-regular-20-letter-spacing: 0px;
  --dekstop-body2-regular-20-line-height: 28px;
  --dekstop-heading-semi-bold-18-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-18-font-size: 18px;
  --dekstop-heading-semi-bold-18-font-style: normal;
  --dekstop-heading-semi-bold-18-font-weight: 600;
  --dekstop-heading-semi-bold-18-letter-spacing: 0px;
  --dekstop-heading-semi-bold-18-line-height: 24px;
  --dekstop-heading-semi-bold-24-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-24-font-size: 24px;
  --dekstop-heading-semi-bold-24-font-style: normal;
  --dekstop-heading-semi-bold-24-font-weight: 600;
  --dekstop-heading-semi-bold-24-letter-spacing: 0px;
  --dekstop-heading-semi-bold-24-line-height: 32px;
  --dekstop-heading-semi-bold-32-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-32-font-size: 32px;
  --dekstop-heading-semi-bold-32-font-style: normal;
  --dekstop-heading-semi-bold-32-font-weight: 600;
  --dekstop-heading-semi-bold-32-letter-spacing: 0px;
  --dekstop-heading-semi-bold-32-line-height: 40px;
  --dekstop-heading-semi-bold-40-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-40-font-size: 40px;
  --dekstop-heading-semi-bold-40-font-style: normal;
  --dekstop-heading-semi-bold-40-font-weight: 600;
  --dekstop-heading-semi-bold-40-letter-spacing: 0px;
  --dekstop-heading-semi-bold-40-line-height: 48px;
  --dekstop-heading-semi-bold-64-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-64-font-size: 64px;
  --dekstop-heading-semi-bold-64-font-style: normal;
  --dekstop-heading-semi-bold-64-font-weight: 600;
  --dekstop-heading-semi-bold-64-letter-spacing: 0px;
  --dekstop-heading-semi-bold-64-line-height: 72px;
  --dekstop-link-regular-24-font-family: "Inter", Helvetica;
  --dekstop-link-regular-24-font-size: 24px;
  --dekstop-link-regular-24-font-style: normal;
  --dekstop-link-regular-24-font-weight: 400;
  --dekstop-link-regular-24-letter-spacing: 0px;
  --dekstop-link-regular-24-line-height: 32px;
  --gray: #afb0bc;
  --light-blue: #ebedfc;
  --red: #f15757;
  --white: #fff;
}
/*# sourceMappingURL=index.ad27bb9a.css.map */
