@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --black: rgba(14, 14, 14, 1);
  --blue: rgba(57, 73, 222, 1);
  --dark-blue: rgba(45, 63, 225, 1);
  --dark-gray: rgba(153, 153, 153, 1);
  --dekstop-body-regular-16-font-family: "Inter", Helvetica;
  --dekstop-body-regular-16-font-size: 16px;
  --dekstop-body-regular-16-font-style: normal;
  --dekstop-body-regular-16-font-weight: 400;
  --dekstop-body-regular-16-letter-spacing: 0px;
  --dekstop-body-regular-16-line-height: 24px;
  --dekstop-body-regular-20-font-family: "Inter", Helvetica;
  --dekstop-body-regular-20-font-size: 20px;
  --dekstop-body-regular-20-font-style: normal;
  --dekstop-body-regular-20-font-weight: 400;
  --dekstop-body-regular-20-letter-spacing: 0px;
  --dekstop-body-regular-20-line-height: 28px;
  --dekstop-body2-regular-20-font-family: "Inter", Helvetica;
  --dekstop-body2-regular-20-font-size: 20px;
  --dekstop-body2-regular-20-font-style: normal;
  --dekstop-body2-regular-20-font-weight: 400;
  --dekstop-body2-regular-20-letter-spacing: 0px;
  --dekstop-body2-regular-20-line-height: 28px;
  --dekstop-heading-semi-bold-18-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-18-font-size: 18px;
  --dekstop-heading-semi-bold-18-font-style: normal;
  --dekstop-heading-semi-bold-18-font-weight: 600;
  --dekstop-heading-semi-bold-18-letter-spacing: 0px;
  --dekstop-heading-semi-bold-18-line-height: 24px;
  --dekstop-heading-semi-bold-24-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-24-font-size: 24px;
  --dekstop-heading-semi-bold-24-font-style: normal;
  --dekstop-heading-semi-bold-24-font-weight: 600;
  --dekstop-heading-semi-bold-24-letter-spacing: 0px;
  --dekstop-heading-semi-bold-24-line-height: 32px;
  --dekstop-heading-semi-bold-32-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-32-font-size: 32px;
  --dekstop-heading-semi-bold-32-font-style: normal;
  --dekstop-heading-semi-bold-32-font-weight: 600;
  --dekstop-heading-semi-bold-32-letter-spacing: 0px;
  --dekstop-heading-semi-bold-32-line-height: 40px;
  --dekstop-heading-semi-bold-40-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-40-font-size: 40px;
  --dekstop-heading-semi-bold-40-font-style: normal;
  --dekstop-heading-semi-bold-40-font-weight: 600;
  --dekstop-heading-semi-bold-40-letter-spacing: 0px;
  --dekstop-heading-semi-bold-40-line-height: 48px;
  --dekstop-heading-semi-bold-64-font-family: "Inter", Helvetica;
  --dekstop-heading-semi-bold-64-font-size: 64px;
  --dekstop-heading-semi-bold-64-font-style: normal;
  --dekstop-heading-semi-bold-64-font-weight: 600;
  --dekstop-heading-semi-bold-64-letter-spacing: 0px;
  --dekstop-heading-semi-bold-64-line-height: 72px;
  --dekstop-link-regular-24-font-family: "Inter", Helvetica;
  --dekstop-link-regular-24-font-size: 24px;
  --dekstop-link-regular-24-font-style: normal;
  --dekstop-link-regular-24-font-weight: 400;
  --dekstop-link-regular-24-letter-spacing: 0px;
  --dekstop-link-regular-24-line-height: 32px;
  --gray: rgba(175, 176, 188, 1);
  --light-blue: rgba(235, 237, 252, 1);
  --red: rgba(241, 87, 87, 1);
  --white: rgba(255, 255, 255, 1);
}
